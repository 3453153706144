import {tippy, TooltipPlugins} from '@ultradent/components';
import 'lazysizes';
import '@/modules/feature-test';
import 'waypoints/lib/noframework.waypoints';
import MicroModal from 'micromodal';
import {toCustomFormToggle} from './modules/form-toggle-field';
import {createMessageBar} from './modules/site-messagebar';
import SiteMenu from './modules/site-menu';
import {anyEmptyOrNil, notEmptyOrNil} from '@ultradent/utilities/EmptyOrNil';
import {createTabs} from '@/modules/tabs';


class Site {

    nodes = {};
    isShowingSiteMenu = false;

    constructor () {
        document.addEventListener( 'DOMContentLoaded', () => this.onDomLoad() );
    }

    onDomLoad () {
        this.nodes.siteHeader = document.getElementById( 'site-header' );
        this.nodes.siteLogo = document.getElementById( 'site-logo' );

        this.patchGoogleTranslate();

        // custom styled radio/checkbox
        this.initFormToggle();
        this.initSiteMenu();
        this.initHeaderStates();
        this.initNotifications();
        this.initModals();
        this.initTabs();
        this.initImages();
        this.initTransitions();
        this.initInteractions();
        this.initIframes();
    }

    /* note - future implementation
     initUser () {
         // check storage for UserObject
         // if user not set then fetch
         //  -- on complete set user
         //  -- on fail retry once
         //  -- on fail again log error
         const user = window.sessionStorage.getItem( 'UpiPortalUser' );
         if ( !user ) {

         }
     }*/

    patchGoogleTranslate () {
        /**
         *  there is an issue with browser plugins that manipulate
         *  the DOM after react has rendered a page causing
         *  errors when a mutated dom node is attempted
         *  to be removed. React has no knowledge of the
         *  newly added nodes and throws an error because
         *  it is not registered in its virtual dom
         *
         *  this is a well known issue without an easy fix
         *  aside from suppressing the exception altogether
         *  and logging the error to console instead
         */
        // resource - https://github.com/facebook/react/issues/11538#issuecomment-417504600
        if ( typeof Node === 'function' && Node.prototype ) {
            const originalRemoveChild = Node.prototype.removeChild;
            Node.prototype.removeChild = function ( child ) {
                if ( child.parentNode !== this ) {
                    if ( console ) {
                        console.error( 'Cannot remove a child from a different parent', child, this );
                    }
                    return child;
                }
                return originalRemoveChild.apply( this, arguments );
            }

            const originalInsertBefore = Node.prototype.insertBefore;
            Node.prototype.insertBefore = function ( newNode, referenceNode ) {
                if ( referenceNode && referenceNode.parentNode !== this ) {
                    if ( console ) {
                        console.error( 'Cannot insert before a reference node from a different parent',
                            referenceNode,
                            this );
                    }
                    return newNode;
                }
                return originalInsertBefore.apply( this, arguments );
            }
        }
    }

    initModals () {
        MicroModal.init( {
            disableScroll: true,
            openTrigger: 'data-modal-open', // [3]
            closeTrigger: 'data-modal-close' //
        } );
    }

    initFormToggle () {
        // apply custom radio styles
        const formToggleFields = document.querySelectorAll( 'input[type="radio"],input[type="checkbox"]' );
        Array.from( formToggleFields ).forEach( toCustomFormToggle );
    }

    initSiteMenu () {
        const menuWrap = document.getElementById( 'nav-site-menu--wrap' );
        if ( menuWrap ) {
            new SiteMenu( menuWrap );
        }
    }

    initTabs () {
        const tabs = document.querySelectorAll( '[data-tabs]' );
        if ( notEmptyOrNil( tabs ) ) {
            [...tabs].forEach( el => createTabs( el ) )
        }
    }

    initImages () {
        const handleImage404 = function ( e ) {
            const el = e.target;
            if ( el.nodeName.toLowerCase() === 'img' ) {
                el.classList.add( 'img-broken' );
            }
        };
        // bind to event capture phase since 'error' events do not bubble
        document.body.addEventListener( 'error', handleImage404, true );
    }

    initHeaderStates () {
        const {siteHeader, siteLogo} = this.nodes;

        if ( anyEmptyOrNil( [siteHeader, siteLogo] ) ) {
            return;
        }

        let lastKnownScrollPosition = 0;
        let scrollDir = 0;
        let ticking = false;
        const states = {
            VISIBLE: 'oncanvas',
            HIDDEN: 'offcanvas'
        };

        // toggle header visibility
        function handleScroll ( dir, lastPos ) {
            const isDownDir = dir === 'down';
            if ( isDownDir && lastPos > siteLogo.getBoundingClientRect().height * 4 ) {
                toggleHeaderVisibility( false );
                return;
            }
            toggleHeaderVisibility( true );
        }

        function toggleHeaderVisibility ( isVisible ) {
            siteHeader.dataset.headerState = isVisible ? states.VISIBLE : states.HIDDEN;
        }

        document.addEventListener( 'scroll', function ( e ) {
            scrollDir = Math.sign( lastKnownScrollPosition - window.scrollY );
            lastKnownScrollPosition = window.scrollY;
            if ( !ticking ) {
                window.requestAnimationFrame( function () {
                    handleScroll( scrollDir > 0 ? 'up' : 'down', lastKnownScrollPosition );
                    ticking = false;
                } );

                ticking = true;
            }
        } );

        // toggle menu icon placement

        // note - initialize page scroll to 0,0 to fix page load issue that triggers unwanted waypoint.
        //  Waypoint for some reason triggers a scroll half-down the page the resets back to 0 triggering the waypoint
        //  handler twice along with header  animations.
        window.scrollTo( 0, 0 );

        new window.Waypoint( {
            element: document.getElementById( 'body-content' ),
            handler: function ( direction ) {
                const isDownDir = direction === 'down';
                if ( isDownDir ) {
                    siteHeader.dataset.logoState = states.HIDDEN;
                    return;
                }
                siteHeader.dataset.logoState = states.VISIBLE;
            },
            offset: -(siteHeader.getBoundingClientRect().height)
        } );
    }

    initTransitions () {
        const transitionEl = document.querySelectorAll( '[data-transition-waypoint]' );

        [...transitionEl].forEach( element => {
            new window.Waypoint( {
                element,
                handler: function ( direction ) {
                    const el = this.element;
                    const transitionClass = el.dataset.transitionWaypoint;
                    el.classList.remove( transitionClass );
                    el.classList.add( transitionClass );
                }
            } );

        } );
    }

    initNotifications () {
        // site message bar and notifications
        createMessageBar( document.querySelector( '#js-site-message-bar' ) );

        const getPopoverTemplate = ref => {
            const id = ref.getAttribute( 'data-tip-template' );
            const template = document.getElementById( id );
            return template.innerHTML;
        };
        // default tooltips
        tippy( '[data-tip-content]', {
            content: ref => ref.getAttribute( 'data-tip-content' )
        } );
        // popovers
        tippy( '[data-tip-popover]', {
            content: getPopoverTemplate,
            allowHTML: true,
            arrow: false,
            interactive: true,
            theme: 'light',
            touch: true,
            placement: 'bottom-end'
        } );

        tippy( '[data-tip-popover-click]', {
            plugins: [TooltipPlugins.hideOnEsc],
            content: getPopoverTemplate,
            trigger: 'click',
            allowHTML: true,
            arrow: false,
            interactive: true,
            theme: 'light',
            placement: 'bottom-end',
            touch: true
        } );
    }

    initInteractions () {
        document.addEventListener( 'click', ( e ) => {
            // note -> any button/CTA with data-interaction-feedback will display feedback indicator onClick
            const isInteraction = e.target.dataset.interaction;
            if ( !isInteraction ) {
                return;
            }

            e.target.classList.add( 'processing' );
        } )
    }

    initIframes () {
        const iframes = document.querySelectorAll( 'iframe.h-full' );

        function updateIframes () {
            [...iframes].forEach( trySizeFrame );
        }

        function getFrameDoc ( frame ) {
            return frame.contentDocument || frame.contentWindow.document;
        }

        function getSmartsheetRoot ( frame ) {
            const doc = getFrameDoc( frame );
            return doc.querySelector( '#root [role="main"] > div:first-child' );
        }

        function checkFrameLoaded ( f ) {
            const doc = getFrameDoc( f );
            return doc?.readyState === 'complete' && !f.classList.contains( 'lazyloading' );
        }

        function sizeIframe ( f ) {
            // look for the #root element within the SmartSheets form
            const doc = getFrameDoc( f );
            const root = getSmartsheetRoot( f );
            if ( root ) {
                f.style.height = `${root.scrollHeight}px`;
                return;
            }
            // if not present then use the iframe body
            f.style.height = `${doc.body.scrollHeight}px`;
        }

        function observeFrameChanges ( f ) {
            if ( checkFrameLoaded( f ) ) {
                const doc = getFrameDoc( f );
                const root = getSmartsheetRoot( f );
                const observNode = root ? root : doc.body;
                const observer = new ResizeObserver( () => sizeIframe( f ) );
                observer.observe( observNode );
                return;
            }

            setTimeout( () => observeFrameChanges( f ), 300 );
        }

        function trySizeFrame ( f ) {
            if ( checkFrameLoaded( f ) ) {
                sizeIframe( f );
                return;
            }

            setTimeout( () => trySizeFrame( f ), 300 );
        }

        // notEmptyOrNil( iframes )
        if ( iframes !== null && iframes.length > 0 ) {
            [...iframes].forEach( observeFrameChanges );
            window.addEventListener( 'load', updateIframes );
            window.addEventListener( 'resize', updateIframes );
        }
    }
}

new Site();

