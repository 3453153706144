window.upi = Object.assign( {}, window.upi || {} );

export default function ( spaceName, scope ) {
    let parts = spaceName.split( '.' ),
        parent = scope || window.upi;

    // filter redundant ns global
    if ( parts[0] === 'upi' ) {
        parts = parts.slice( 1 );
    }

    for ( let i = 0, l = parts.length; i < l; i++ ) {
        // create ns if not exists
        if ( typeof parent[parts[i]] === 'undefined' ) {
            parent[parts[i]] = {};
        }
        parent = parent[parts[i]];
    }

    return parent;
}




