import namespace from './namespace';
import {googleAnalyticsLayer} from './data-layer-ga4';

const dataLayer = namespace( 'dataLayer' );
const dataLayerProto = {
    // todo - getUser?
};

dataLayer.create = ( obj ) => Object.assign( {}, dataLayerProto, obj );

const resourceCenter = dataLayer.create( {
    fileDownload: ( assets, sizeAlias ) => {
        googleAnalyticsLayer.fileDownload( assets, sizeAlias );
    }
} );
dataLayer.resourceCenter = resourceCenter;

export default dataLayer;
