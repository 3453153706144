export const GACustomEvent = {
    FORM_SUBMIT: 'ajax_form_submit',
    IMPRESSION: 'view_item_list',
    FILE_DOWNLOAD: 'file_download'
};

export const GACommands = {
    CONFIG: 'config',
    GET: 'get',
    SET: 'set',
    EVENT: 'event',
    CONSENT: 'consent'
};

const googleAnalyticsProto = {
    sendEvent ( eventName, data ) {
        this.setDataLayer( {
            [GACommands.EVENT]: eventName,
            ...data
        } );

        console.log( '[dataLayer.GA4] send event', eventName, data );
        return this;
    },

    setDataLayer ( data ) {
        if ( !window.dataLayer ) {
            window.dataLayer = [];
        }

        window.dataLayer.push( data );

        return this;
    }

};

export const googleAnalyticsLayer = Object.assign( {}, googleAnalyticsProto, {
    fileDownload ( assets, sizeAlias ) {
        assets.forEach( asset => {
            const sizedAsset = asset.sizes.find( ({alias}) => alias.toLowerCase() === sizeAlias?.toLowerCase() ) ?? asset.sizes[0];

            this.sendEvent( GACustomEvent.FILE_DOWNLOAD, {
                file_name: asset.name,
                file_extension: asset.fileExtension,
                file_size_alias: sizedAsset.alias,
                file_url: sizedAsset.url
            } );
        } );

    }
} );
